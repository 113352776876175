.modalContainer-modal { 
  width:  960px ;
  border-radius:  inherit ;
  }
.infoPanel-modal { 
  width:  300px ;
  height:  650px ;
  max-height:  80vh ;
  background-color:  #F0F0F0 ;
  border-top-left-radius:  inherit ;
  border-bottom-left-radius:  inherit ;
  align-items:  center ;
  padding:  0 32px ;
  }

.contentPanel-modal ::-webkit-scrollbar {
    display: none;
  }
.contentPanel-modal { 
  border-bottom-right-radius:  inherit ;
  border-top-right-radius:  inherit ;
  overflow-y:  scroll ;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding:  8px 32px ;
  }

.actionBar-modal{ 
  height:  72px ;
  background-color:  #F8F8F8 ;
  border-bottom-right-radius:  inherit ;
  }
.topBar-modal { 
  padding-right:  60px ;
  padding-left:  60px ;
  height:  60px ;
  border-top-right-radius:  inherit ;
  }
.headerIconPosition-modal { 
  margin-top: -50px;
  margin-bottom: 10px;
  }
.contentContainer-modal { 
  border-top-right-radius:  inherit ;
  border-bottom-right-radius:  inherit ;
  }
.loaderContainer-modal { 
  height:  100% ;
  }
.loader-modal { 
  text-align:  center ;
  }
